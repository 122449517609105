export const customerStoryGql = `... on blog_customerStory_Entry {
  typeHandle
  title
  navigationTheme
  customerStoryReadMoreButton
  blogCategory(orderBy: "lft asc") {
    id
    level
    title
    uri
    ... on blog_Category {
      breadcrumbLink {
        uri
      }
    }
  }
  customerStoryContent {
    ... on customerStoryContent_basicText_BlockType {
      typeHandle
      textBlocks {
        ... on textBlocks_BlockType {
          eyebrow
          subheader
          text
          textAlignment
          primaryCtaBtn {
            ariaLabel
            customText
            target
            text
            title
            type
            url
          }
          primaryCtaBtnLabel
          primaryCtaBtnAriaLabel
          primaryCtaBtnTheme
          primaryCtaBtnOpenInANewWindow
        }
      }
      columns
      colors
    }
    ... on customerStoryContent_header_BlockType {
      typeHandle
      eyebrowText
      pageTitle
      leadParagraph
      detailsTable {
        detailTitle
        detailDescription @markdown
      }
    }
    ... on customerStoryContent_relatedProducts_BlockType {
      typeHandle
      pageTitle
      relatedProduct {
        ... on product_productPage_Entry {
          uri
          relatedProductTitle
          relatedProductDescriptor
          relatedProductDescription
          relatedProductImage {
            ... on images_Asset {
              lpImageGridMasonry {
                srcWebp
                srcset
              }
            }
          }
        }
        ... on product_productPage2022_Entry {
          uri
          relatedProductTitle
          relatedProductDescriptor
          relatedProductDescription
          relatedProductImage {
            ... on images_Asset {
              lpImageGridMasonry {
                srcWebp
                srcset
              }
            }
          }
        }
      }
    }
    ... on customerStoryContent_text_BlockType {
      typeHandle
      text
      header
      sectionDivider
    }
    ... on customerStoryContent_productOverviewGallery_BlockType {
      typeHandle
      pageTitle
      displayInHeaderQuicklinks
      displayTitle
      displayThumbnailNav
      galleryAssets {
        ... on images_Asset {
          title
          caption
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
        ... on videos_Asset {
          title
          caption
          videoId
          videoSource
          galleryThumbnail {
            srcset
            srcWebp
          }
          galleryEnlarged {
            srcset
            srcWebp
          }
        }
      }
    }
    ... on customerStoryContent_blockquote_BlockType {
      typeHandle
      blockQuote {
        ... on blockQuote_BlockType {
          enableQuote
          quote
          quoteAuthor
          descriptor
          workplace
          authorImage {
            ... on images_Asset {
              aspectRatio11 {
                srcWebp
                srcset
              }
            }
          }
        }
      }
    }
    ... on customerStoryContent_links_BlockType {
      typeHandle
      linkObject {
        ... on linkObject_BlockType {
          customUrl
          linkLabel
          openInANewWindow
          asset {
            url
            kind
          }
          entry {
            uri
          }
        }
      }
    }
    ... on customerStoryContent_relatedCustomerStories_BlockType {
      typeHandle
      sectionTitle
      customerStories {
        ... on blog_customerStory_Entry {
          cardTitle
          cardDescription
          location
          customerStorySecondaryTitle
          customerStorySecondaryDescription
          uri
        }
      }
    }
    ... on customerStoryContent_promoBlock_BlockType {
      typeHandle
      displayTitle
      customerStoryStyle
      customerStory {
        ... on blog_customerStory_Entry {
          cardTitle
          cardDescription
          location
          customerStorySecondaryTitle
          customerStorySecondaryDescription
          uri
          customerStoryImage {
            ... on images_Asset {
              uncropped1920w {
                srcWebp
                srcset
                src
              }
            }
          }
        }
      }
    }
    ... on customerStoryContent_promo_BlockType {
      typeHandle
      promoEntry {
        typeHandle
        ... on event_eventV2_Entry {
          promoTabStyle
          promoCardStyle
          promoDescription
          promoTitle
          promoImage {
            ... on images_Asset {
              lpImageTextUncropped {
                optimizedImageUrls
                srcset
                srcWebp
              }
              lpImageTextUncropped {
                optimizedImageUrls
              }
            }
          }
          eventFilters {
            ... on eventFilters_Category {
              slug
              title
              parent {
                slug
              }
            }
          }
          eventRecordingUrl
          eventStartDateTime @formatDateTime(format: "F j, Y")
          linksMatrix2 {
            ... on linksMatrix2_entry_BlockType {
              buttonLabel
              ariaLabel
              entry {
                uri
              }
              openInANewWindow
            }
            ... on linksMatrix2_custom_BlockType {
              ariaLabel
              buttonLabel
              urlText
              openInANewWindow
            }
            ... on linksMatrix2_asset_BlockType {
              ariaLabel
              buttonLabel
              openInANewWindow
              asset {
                uri
              }
            }
            ... on linksMatrix2_productCategory_BlockType {
              ariaLabel
              buttonLabel
              productCategory {
                uri
              }
              openInANewWindow
            }
          }
        }
        ... on whitePaper_whitePaper_Entry {
          promoTabStyle
          promoEyebrow
          promoCardStyle
          promoDescription
          promoTitle
          promoImage {
            ... on images_Asset {
              lpImageTextUncropped {
                optimizedImageUrls
                srcset
                srcWebp
              }
              lpImageTextUncropped {
                optimizedImageUrls
              }
            }
          }
          linksMatrix2 {
            ... on linksMatrix2_entry_BlockType {
              buttonLabel
              ariaLabel
              entry {
                uri
              }
              openInANewWindow
            }
            ... on linksMatrix2_custom_BlockType {
              ariaLabel
              buttonLabel
              urlText
              openInANewWindow
            }
            ... on linksMatrix2_asset_BlockType {
              ariaLabel
              buttonLabel
              openInANewWindow
              asset {
                uri
              }
            }
            ... on linksMatrix2_productCategory_BlockType {
              ariaLabel
              buttonLabel
              productCategory {
                uri
              }
              openInANewWindow
            }
          }
        }
        ... on interactiveWebglDemo_interactiveWebGLDemo_Entry {
          promoTabStyle
          promoEyebrow
          promoCardStyle
          promoDescription
          promoTitle
          promoImage {
            ... on images_Asset {
              lpImageTextUncropped {
                optimizedImageUrls
                srcset
                srcWebp
              }
              lpImageTextUncropped {
                optimizedImageUrls
              }
            }
          }
          linksMatrix2 {
            ... on linksMatrix2_entry_BlockType {
              buttonLabel
              ariaLabel
              entry {
                uri
              }
              openInANewWindow
            }
            ... on linksMatrix2_custom_BlockType {
              ariaLabel
              buttonLabel
              urlText
              openInANewWindow
            }
            ... on linksMatrix2_asset_BlockType {
              ariaLabel
              buttonLabel
              openInANewWindow
              asset {
                uri
              }
            }
            ... on linksMatrix2_productCategory_BlockType {
              ariaLabel
              buttonLabel
              productCategory {
                uri
              }
              openInANewWindow
            }
          }
        }
      }
    }
  }
}`;
